import React from "react"
import cx from "classnames"

import { IMG } from "../../const"
import "../../assets/styles/commons/_common-kit.scss"
import * as styles from "../../assets/styles/bma.module.scss"

const PlatformFeatures = () => (
  <ul className={styles.variableOrder}>
    <li>
      <div className={styles.textCol}>
        <h3>Fairing Dashboard</h3>
        <p>
          Parents are provided with a comprehensive dashboard with clear data
          visualization, enabling them to track their children's progress
          effectively. The dashboard presents information in a user-friendly
          format, facilitating easy monitoring of academic achievements. Plus,
          there is no limit to the number of children that can be included in
          the system.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.bgColor}>
          <picture className={styles.smallSingle}>
            <source
              srcSet={`${IMG.BMA}/bma-features-1@2x.avif,  ${IMG.BMA}/bma-features-1@2x.avif 2x, ${IMG.BMA}/bma-features-1@3x.avif 3x`}
              type="image/avif"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-1@2x.webp,  ${IMG.BMA}/bma-features-1@2x.webp 2x, ${IMG.BMA}/bma-features-1@3x.webp 3x`}
              type="image/webp"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-1@2x.jpg,  ${IMG.BMA}/bma-features-1@2x.jpg 2x, ${IMG.BMA}/bma-features-1@3x.jpg 3x`}
            />
            <img
              src={`${IMG.BMA}/bma-features-1.jpg`}
              alt="Screen of a dashboard"
              width="240"
              height="358"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textCol}>
        <h3>Kids Teach Kids</h3>
        <p>
          This innovative peer-to-peer video system allows users to assume the
          roles of both teacher and student. Users can upload instructional
          videos to their channels and interact with content from other media,
          fostering a collaborative learning environment.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.bgColor}>
          <div className={cx(styles.doubleImages, styles.small)}>
            <picture className={styles.topImg}>
              <source
                srcSet={`${IMG.BMA}/bma-features-2-1@2x.avif,  ${IMG.BMA}/bma-features-2-1@2x.avif 2x, ${IMG.BMA}/bma-features-2-1@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.BMA}/bma-features-2-1@2x.webp,  ${IMG.BMA}/bma-features-2-1@2x.webp 2x, ${IMG.BMA}/bma-features-2-1@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.BMA}/bma-features-2-1@2x.jpg,  ${IMG.BMA}/bma-features-2-1@2x.jpg 2x, ${IMG.BMA}/bma-features-2-1@3x.jpg 3x`}
              />
              <img
                src={`${IMG.BMA}/bma-features-2-1.jpg`}
                alt="Screen of Blue Manor Academy"
                width="344"
                height="209"
                loading="lazy"
              />
            </picture>

            <picture className={styles.bottomImg}>
              <source
                srcSet={`${IMG.BMA}/bma-features-2-2@2x.avif,  ${IMG.BMA}/bma-features-2-2@2x.avif 2x, ${IMG.BMA}/bma-features-2-2@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.BMA}/bma-features-2-2@2x.webp,  ${IMG.BMA}/bma-features-2-2@2x.webp 2x, ${IMG.BMA}/bma-features-2-2@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.BMA}/bma-features-2-2@2x.jpg,  ${IMG.BMA}/bma-features-2-2@2x.jpg 2x, ${IMG.BMA}/bma-features-2-2@3x.jpg 3x`}
              />
              <img
                src={`${IMG.BMA}/bma-features-2-2.jpg`}
                alt="Screen"
                width="256"
                height="214"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textCol}>
        <h3>Customizable Curriculum</h3>
        <p>
          Parents can personalize their children's curriculum according to their
          specific requirements. They can customize the subjects by
          incorporating material from different grade levels, ensuring an
          educational experience tailored to their child's unique needs.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.bgColor}>
          <picture className={styles.single}>
            <source
              srcSet={`${IMG.BMA}/bma-features-3@2x.avif,  ${IMG.BMA}/bma-features-3@2x.avif 2x, ${IMG.BMA}/bma-features-3@3x.avif 3x`}
              type="image/avif"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-3@2x.webp,  ${IMG.BMA}/bma-features-3@2x.webp 2x, ${IMG.BMA}/bma-features-3@3x.webp 3x`}
              type="image/webp"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-3@2x.jpg,  ${IMG.BMA}/bma-features-3@2x.jpg 2x, ${IMG.BMA}/bma-features-3@3x.jpg 3x`}
            />
            <img
              src={`${IMG.BMA}/bma-features-3.jpg`}
              alt="Screen of a customizable curriculum"
              width="390"
              height="246"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textCol}>
        <h3>Admin Panel for&nbsp;Content Management</h3>
        <p>
          We have developed an intuitive admin panel that simplifies content
          management tasks. The board empowers content managers to populate the
          platform with educational materials and add new jobs, ensuring a
          seamless user experience.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.bgColor}>
          <picture className={styles.single}>
            <source
              srcSet={`${IMG.BMA}/bma-features-4@2x.avif,  ${IMG.BMA}/bma-features-4@2x.avif 2x, ${IMG.BMA}/bma-features-4@3x.avif 3x`}
              type="image/avif"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-4@2x.webp,  ${IMG.BMA}/bma-features-4@2x.webp 2x, ${IMG.BMA}/bma-features-4@3x.webp 3x`}
              type="image/webp"
            />
            <source
              srcSet={`${IMG.BMA}/bma-features-4@2x.jpg,  ${IMG.BMA}/bma-features-4@2x.jpg 2x, ${IMG.BMA}/bma-features-4@3x.jpg 3x`}
            />
            <img
              src={`${IMG.BMA}/bma-features-4.jpg`}
              alt="Screen of an admin panel"
              width="390"
              height="367"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textCol}>
        <h3>Convenient Library</h3>
        <p>
          Our platform features a user-friendly library, offering a vast
          collection of books categorized by grade level, literary genres, and
          authors. This organization facilitates quick and easy access to the
          desired resources, supporting a seamless learning journey.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.bgColor}>
          <div className={styles.doubleImages}>
            <div className={styles.doubleImages}>
              <picture className={styles.topImg}>
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-1@2x.avif,  ${IMG.BMA}/bma-features-5-1@2x.avif 2x, ${IMG.BMA}/bma-features-5-1@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-1@2x.webp,  ${IMG.BMA}/bma-features-5-1@2x.webp 2x, ${IMG.BMA}/bma-features-5-1@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-1@2x.jpg,  ${IMG.BMA}/bma-features-5-1@2x.jpg 2x, ${IMG.BMA}/bma-features-5-1@3x.jpg 3x`}
                />
                <img
                  src={`${IMG.BMA}/bma-features-5-1.jpg`}
                  alt="Screen of a library"
                  width="344"
                  height="251"
                  loading="lazy"
                />
              </picture>

              <picture className={styles.bottomImg}>
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-2@2x.avif,  ${IMG.BMA}/bma-features-5-2@2x.avif 2x, ${IMG.BMA}/bma-features-5-2@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-2@2x.webp,  ${IMG.BMA}/bma-features-5-2@2x.webp 2x, ${IMG.BMA}/bma-features-5-2@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  srcSet={`${IMG.BMA}/bma-features-5-2@2x.jpg,  ${IMG.BMA}/bma-features-5-2@2x.jpg 2x, ${IMG.BMA}/bma-features-5-2@3x.jpg 3x`}
                />
                <img
                  src={`${IMG.BMA}/bma-features-5-2.jpg`}
                  alt="Screen of a library"
                  width="270"
                  height="181"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
)
export default PlatformFeatures
