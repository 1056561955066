export const TECHNOLOGIES = [
  { name: "React", icon: "react-native.svg" },
  { name: "Python", icon: "python.svg" },
  { name: "Figma", icon: "icon-figma.svg" },
  { name: "AWS", icon: "aws.svg" },
  { name: "HTML", icon: "html5.svg" },
  { name: "CSS", icon: "css3.svg" },
  { name: "Postgres", icon: "postgresql.svg" },
  { name: "JavaScript", icon: "javascript.svg" },
  { name: "jQuery", icon: "jquery.svg" },
]

export const TEAM = [
  { id: 1, name: "Sales Team", image: "sales-team", className: "navy" },
  {
    id: 2,
    name: "Business Analyst",
    image: "business-analysis",
    className: "navy",
  },
  {
    id: 3,
    name: "Project Manager",
    image: "project-management",
    className: "gray",
  },
  {
    id: 4,
    name: "UI/UX Designer",
    image: "",
    className: "sand",
  },
  {
    id: 5,
    name: "Solution Architect",
    image: "",
    className: "dark-gray",
  },
  {
    id: 6,
    name: "Front-end Developer",
    image: "frontend-dev",
    className: "brown",
  },
  {
    id: 7,
    name: "Back-end Developer",
    image: "backend-dev",
    className: "light-brown",
  },
  { id: 8, name: "DevOps", image: "devops-engineer", className: "sand" },
  {
    id: 9,
    name: "QA specialist",
    image: "qa-engineer",
    className: "light-navy",
  },
]

export const GAMES_LIST = [
  {
    title: "Memorization Game",
    text:
      "We designed an engaging internal training game to enhance users' memory skills. Through interactive  exercises and challenges, this game effectively promotes memory improvement.",
  },
  {
    title: "Genius Game",
    text:
      "The Genius game is an internal educational format that expands users' vocabulary. Through fun and interactive activities, this game helps users enhance their word knowledge and language skills.",
  },
  {
    title: "Challenge Learning System",
    text:
      "Our platform incorporates a challenge learning system, enabling users to create and participate in various challenges. Users can upload challenges in video, audio, or picture formats, fostering a collaborative and interactive learning environment. This feature encourages users to share their knowledge and skills while supporting and engaging with challenges created by others.",
  },
  {
    title: "Puzzle Game",
    text:
      "The Puzzle Game is an internal educational format that enhances users' memory capabilities. Users can sharpen their memory skills by engaging in puzzle-based activities while enjoying an interactive learning experience.",
  },
]
