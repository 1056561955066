import React from "react"
import cx from "classnames"

import { IMG } from "../../const"
import "../../assets/styles/commons/_common-kit.scss"
import * as styles from "../../assets/styles/bma.module.scss"

const RequestScheme = () => (
  <ul className={cx(styles.variableOrder, styles.revert)}>
    <li>
      <div className={styles.textCol}>
        <h3>Migration From Monolith to Microservices</h3>
        <p>
          Effectively designed and implemented microservices offer solutions to
          common issues encountered in monolithic architecture. These include
          challenges related to system scalability, sluggish delivery processes,
          and the complexities of identifying and verifying defects. In a
          monolithic structure, the codebase is usually tightly coupled and
          interconnected, making it difficult to troubleshoot, modify, or scale
          individual components.
        </p>
        <p>
          The entire application is deployed as a single unit, which can make it
          difficult to roll out fixes, minor adjustments, or new features
          without affecting the entire system. Testing, debugging, and
          conducting maintenance on the system can be even more challenging, as
          changes made to one part of the system can potentially impact other
          parts of the application.
        </p>
        <p>
          System scalability faces several challenges if a monolithic
          architecture is used because it contains all functionalities within a
          single code base. The entire application must be scaled as
          requirements change. Microservices are an architectural approach to
          building and deploying software applications that involves breaking
          down an application into smaller, independent services that can be
          developed, deployed, and maintained separately. Microservices
          applications are more resilient thanks to independent deployment and
          loose coupling if compared to tight coupling in monolith applications.
        </p>
      </div>
      <div className={styles.imgCol}>
        <div className={styles.imgCol__double}>
          <picture>
            <source
              media="(max-width: 900px)"
              srcSet={`${IMG.BMA}/monolithic-architecture-mobile.svg`}
            />
            <source
              media="(min-width: 901px)"
              srcSet={`${IMG.BMA}/monolithic-architecture.svg`}
            />
            <img
              src={`${IMG.BMA}/monolithic-architecture.svg`}
              alt="Schema of monolithic architecture"
              width="200"
              height="358"
              loading="lazy"
            />
          </picture>
          <picture>
            <source
              media="(max-width: 900px)"
              srcSet={`${IMG.BMA}/microservices-mobile.svg`}
            />
            <source
              media="(min-width: 901px)"
              srcSet={`${IMG.BMA}/microservices.svg`}
            />
            <img
              src={`${IMG.BMA}/microservices.svg`}
              alt="Schema of microservices"
              width="200"
              height="358"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </li>
    <li>
      <div className={styles.textCol}>
        <h3>Study Program</h3>
        <p>
          The study program at Blue Manor Academy is structured into three
          distinct levels:
        </p>
        <ul className={styles.listStyleDisc}>
          <li>Kindergarten</li>
          <li>Preschool</li>
          <li>Grade School (up to 8th grade)</li>
        </ul>
        <p>
          Each level offers a unique curriculum that goes beyond the traditional
          subjects typically taught in educational institutions.
        </p>
        <p>
          To foster student engagement and active participation, the educational
          material is presented in an interactive format. Students have access
          to a wide range of activities, games, clubs, and online classroom
          resources. This approach encourages a dynamic and immersive learning
          experience, making education enjoyable and stimulating for students at
          every level.
        </p>
      </div>
      <div className={cx(styles.imgCol, styles.singleImg)}>
        <picture>
          <source
            media="(max-width: 900px)"
            srcSet={`${IMG.BMA}/study-program-mobile.svg`}
          />
          <source
            media="(min-width: 901px)"
            srcSet={`${IMG.BMA}/study-program.svg`}
          />
          <img
            src={`${IMG.BMA}/study-program.svg`}
            alt="Steps of study program"
            width="548"
            height="212"
            loading="lazy"
          />
        </picture>
      </div>
    </li>
  </ul>
)

export default RequestScheme
