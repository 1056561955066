import React from "react"

import "../../assets/styles/commons/_common-kit.scss"
import * as styles from "../../assets/styles/bma.module.scss"

const Text = () => (
  <p className={styles.bgColor}>
    <mark>
      The client expressed contentment with the caliber and professionalism of
      our work, leading them to choose LaSoft as the primary vendor for the
      ongoing development of the educational platform.
    </mark>
  </p>
)

export default Text
