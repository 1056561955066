// extracted by mini-css-extract-plugin
export var bgColor = "bma-module--bgColor--14bfb";
export var billetShadow = "bma-module--billet-shadow--fe0e7";
export var bmaBlock = "bma-module--bmaBlock--be46b";
export var bottomImg = "bma-module--bottomImg--ea037";
export var cardsList = "bma-module--cardsList--a2c04";
export var colorScheme__background__grayPale = "bma-module--colorScheme__background__gray-pale--5be88";
export var details = "bma-module--details--b52f3";
export var doubleImages = "bma-module--doubleImages--98d66";
export var feedback = "bma-module--feedback--3c621";
export var heading = "bma-module--heading--685d6";
export var icomoon = "bma-module--icomoon--17f22";
export var img = "bma-module--img--3187a";
export var imgCol = "bma-module--imgCol--1a338";
export var imgCol__double = "bma-module--imgCol__double--72895";
export var imgWrapper = "bma-module--imgWrapper--df04a";
export var item__icon = "bma-module--item__icon--ee102";
export var item__inner = "bma-module--item__inner--4b876";
export var item__text = "bma-module--item__text--f71a8";
export var item__title = "bma-module--item__title--db3cc";
export var largeText = "bma-module--largeText--d5cca";
export var listStyleDisc = "bma-module--listStyleDisc--6dfb6";
export var listsBlock = "bma-module--listsBlock--eeef6";
export var lowercase = "bma-module--lowercase--d49ff";
export var mainImg = "bma-module--mainImg--f0412";
export var mainImgWrapper = "bma-module--mainImgWrapper--5c5c4";
export var mainList = "bma-module--mainList--42d8f";
export var mainList__column = "bma-module--mainList__column--39bd7";
export var membersContainer = "bma-module--membersContainer--72c18";
export var process = "bma-module--process--d16c4";
export var process__item = "bma-module--process__item--d639d";
export var process__list = "bma-module--process__list--8356f";
export var revert = "bma-module--revert--3ff67";
export var schema = "bma-module--schema--ebb3a";
export var shine = "bma-module--shine--5b870";
export var single = "bma-module--single--dfd16";
export var singleImg = "bma-module--singleImg--fc5e8";
export var small = "bma-module--small--1442b";
export var smallSingle = "bma-module--smallSingle--26ff5";
export var strong = "bma-module--strong--e3f9d";
export var technologiesBlock = "bma-module--technologiesBlock--e8923";
export var textCol = "bma-module--textCol--6884c";
export var threeImgs = "bma-module--threeImgs--11ac7";
export var topImg = "bma-module--topImg--ec4c7";
export var transition = "bma-module--transition--90725";
export var transitionBackground = "bma-module--transition-background--5ba26";
export var transitionReverse = "bma-module--transition-reverse--0189b";
export var uppercase = "bma-module--uppercase--70f2c";
export var variableOrder = "bma-module--variableOrder--5c7b1";
export var wide = "bma-module--wide--9d72a";